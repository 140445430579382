<!--
--------------------------------------------------------------------------------
<copyright file="NumberOfSalesBySalesperson.vue" company="Bystronic Laser AG">
 Copyright (C) Bystronic Laser AG 2021-2024
</copyright>
--------------------------------------------------------------------------------
-->

<template>
  <section>
    <details>
      <summary>{{ $t('widget_info_text.X-axis') }}</summary>
      <p class="text">
        {{ $t('widget_info_text.number_of_sales_by_salesperson_x_axis') }}
      </p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.Y-axis') }}</summary>
      <p class="text">{{ $t('widget_info_text.salespeople') }}</p>
    </details>
    <details>
      <summary>{{ $t('widget_info_text.bars') }}</summary>
      <p class="text">
        {{ $t('widget_info_text.number_of_sales_by_salesperson_bars_txt') }}
      </p>
    </details>
  </section>
</template>

<script language="ts">
import { Component, Vue } from 'vue-property-decorator';
@Component
export default class NumberOfSalesBySalesperson extends Vue {}
</script>
